import React, { FC, ChangeEvent, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import userService from '../../services/UserServices';
import Terms from '../Terms/Terms';
import { Term } from '../../redux/types/terms';
import { userActions } from '../../redux/slices/userSlice';
import termsService from '../../services/TermsServices';
import { Box, TextField, Button, Stack, Typography, Grid } from '@mui/material';
import { InputAdornment, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import logo_vrt from './../../images/logo_vrt.png';
import onBoarding1 from './../../images/onBoarding1.png';
import onBoarding2 from './../../images/onBoarding2.png';
import { ReactComponent as OnBoardingPage1 } from '../../images/onBoardingPage1.svg';
import { ReactComponent as OnBoardingPage2 } from '../../images/onBoardingPage2.svg';
import { ReactComponent as OnBoardingPage3 } from '../../images/onBoardingPage3.svg';
import { dgv, formatValue } from './AddRUT';
import SwipeableViews from 'react-swipeable-views';
import { Stepper, Step, StepLabel } from '@mui/material';
import DateInput from '../EABCharts/DateInput';

const businesses = [
  {
    name: 'Aramark QB',
    id: 11,
    departments: [
      { name: 'Alimentación', activationCode: 'AB-A3F' },
      { name: 'Aseo y Hotelería', activationCode: 'AB-A40' },
      { name: 'Mantención menor', activationCode: 'AB-A41' },
      { name: 'Retail', activationCode: 'AB-A42' },
      { name: 'Staff', activationCode: 'AB-A43' },
      { name: 'Otro', activationCode: 'AB-A44' },
    ],
  },
  {
    name: 'Teck Quebrada Blanca',
    id: 12,
    departments: [{ name: 'Default', activationCode: 'TC-D3D' }],
  },
  {
    name: 'Otros Quebrada Blanca',
    id: 13,
    departments: [{ name: 'Default', activationCode: 'OD-D3E' }],
  },
];

const steps = ['Info. Personal', 'Info. de Salud', 'Terminos de uso'];

const UserRegister: FC = () => {
  const dispatch = useDispatch();
  const [RUT, setRUT] = useState('');
  const [RUTError, setRUTError] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [birthday, setBirthday] = useState<string | null>(null);
  const [sex, setSex] = useState('');
  const [diabetes, setDiabetes] = useState(false);
  const [thrombosis, setThrombosis] = useState(false);
  const [heartDisease, setHeartDisease] = useState(false);
  const [otherDiseases, setOtherDiseases] = useState('');
  const [terms, setTerms] = useState<Term | null>(null);
  const [accept, setAccept] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [phone, setPhone] = useState('569');
  const [phoneError, setPhoneError] = useState('');
  const [business, setBusiness] = useState<number | null>(null);
  const [activationCode, setActivationCode] = useState('');
  const { token } = useSelector((state: RootState) => state.business);
  useEffect(() => {
    termsService.getTerms().then((response) => setTerms(response));
  }, []);

  const changeRUT = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    const formattedValue = formatValue(value);
    const checkDigit = dgv(+formattedValue.slice(0, -2));
    let validRut = '';
    if (checkDigit.toString() !== formattedValue.charAt(formattedValue.length - 1)) {
      validRut = 'El RUT entregado no es válido';
    }
    setRUT(formattedValue);
    setRUTError(validRut);
    const response = await userService.isRegistered(formattedValue);
    if (response.user !== null) {
      setRUTError('El RUT ingresado ya está registrado');
    }
  };
  const changeName = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setName(event.target.value);
  };
  const changeLastName = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLastName(event.target.value);
  };
  const changePassword = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const password = event.target.value;
    setPassword(password);

    if (password.length < 6) {
      setPasswordError('La contraseña debe tener al menos 6 caracteres');
    } else if (confirmation !== password) {
      setPasswordError('Las contraseñas no coinciden');
    } else {
      setPasswordError('');
    }
  };
  const changeConfirmation = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setConfirmation(event.target.value);
    if (password !== event.target.value) {
      setPasswordError('Las contraseñas no coinciden');
    } else if (event.target.value.length < 6) {
      setPasswordError('La contraseña debe tener al menos 6 caracteres');
    } else {
      setPasswordError('');
    }
  };
  const changeHeight = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      setHeight(event.target.value);
    }
  };
  const changeWeight = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      setWeight(event.target.value);
    }
  };
  const changeBirthday = (event: string | null) => {
    setBirthday(event);
  };
  const changeOtherDiseases = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setOtherDiseases(event.target.value);
  };

  const changeSex = (event: SelectChangeEvent) => {
    setSex(event.target.value as string);
  };
  const register = async () => {
    const response = await userService.createUser(
      {
        email: '',
        rut: RUT,
        password,
        first_name: name,
        last_name: lastName,
        height: +height,
        weight: +weight,
        birthday: birthday ? birthday : '',
        sex,
        diabetes,
        heart_diseases: heartDisease,
        other_diseases: otherDiseases,
        thrombosis,
        business_id: business || 13,
        phone,
      },
      activationCode || 'OD-D3E',
      terms?.id || NaN,
    );
    if (response.user) {
      const { user } = response;
      user.token = response.token;
      if (token !== null && token !== undefined) {
        user.mode = 'active-in';
        dispatch(userActions.login(user));
      } else {
        dispatch(userActions.login(user));
      }
    } else {
      console.log(response);
    }
  };
  const changePhone = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex = /^[0-9\b]+$/;
    if (
      event.target.value === '' ||
      (regex.test(event.target.value) && event.target.value.slice(0, 3) === '569' && event.target.value.length <= 11)
    ) {
      setPhone(event.target.value);
      const response = await userService.phoneIsRegistered(event.target.value);
      if (event.target.value.length === 11 && response.user === null) {
        setPhoneError('');
      } else if (event.target.value.length === 11 && response.user !== null) {
        setPhoneError('El telefono ingresado ya está registrado');
      } else {
        setPhoneError('Debe tener 11 digitos');
      }
    } else if (event.target.value.slice(0, 3) !== '569') {
      setPhoneError('Codigo de area invalido');
    } else if (event.target.value.length <= 10) {
      setPhoneError('Debe tener 11 digitos');
    } else if (event.target.value.length !== 12) {
      setPhoneError('Error al ingresar el número');
    }
  };
  const skipIntro = () => {
    setActiveStep(3);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleBusiness = (event: SelectChangeEvent) => {
    setBusiness(+event.target.value);
    if (+event.target.value !== 11) {
      setActivationCode(
        businesses.find((b) => +b.id === +event.target.value)?.departments[0].activationCode || 'OD-D3E',
      );
    }
  };

  const handleDepartment = (event: SelectChangeEvent) => {
    setActivationCode(event.target.value);
  };

  return (
    <Box sx={{ maxWidth: '100vw', minWidth: '310px', display: 'inline-block' }}>
      {activeStep > 5 && (
        <Box sx={{ width: '101%', marginY: '16px' }}>
          <Stepper activeStep={activeStep - 6} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      )}
      <SwipeableViews disabled index={activeStep}>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <img src={onBoarding1} width={'100%'} height={'450px'} />
            <Box
              sx={{
                position: 'absolute',
                bottom: '30px',
                left: '0px',
                marginTop: '10vh',
                marginX: '15vw',
                width: '70vw',
                maxWidth: '430px',
                maxHeight: '400px',
              }}>
              <OnBoardingPage1 />
            </Box>
          </Box>
          <Box sx={{ paddingX: '5%' }}>
            <Typography variant="h5" sx={{ textAlign: 'center', marginY: '2%', fontWeight: 'bold' }}>
              Encuentra tu rutina ideal
            </Typography>
          </Box>
          <Box sx={{ paddingX: '5%' }}>
            <Typography sx={{ textAlign: 'center', marginY: '5%' }}>
              Te entregamos <b>rutinas de recuperacion según tus necesidades y objetivos!</b> Responde las preguntas y
              obtén tu plan personalizado.
            </Typography>
          </Box>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={6}>
              <Button
                onClick={skipIntro}
                size="large"
                sx={{ marginY: '2%', fontWeight: 'bold', width: '100%' }}
                variant="text">
                OMITIR
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={handleNext}
                size="large"
                sx={{ marginY: '2%', fontWeight: 'bold', width: '100%' }}
                variant="text">
                SIGUIENTE
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <img src={onBoarding2} width={'100%'} height={'450px'} />
            <Box
              sx={{
                position: 'absolute',
                bottom: '30px',
                left: '0px',
                marginTop: '10vh',
                marginX: '15vw',
                width: '70vw',
                maxWidth: '430px',
                maxHeight: '400px',
              }}>
              <OnBoardingPage2 />
            </Box>
          </Box>
          <Box sx={{ paddingX: '5%' }}>
            <Typography variant="h5" sx={{ textAlign: 'center', marginY: '2%', fontWeight: 'bold' }}>
              Vive la experiencia Makana
            </Typography>
          </Box>
          <Box sx={{ paddingX: '5%' }}>
            <Typography sx={{ textAlign: 'center', marginY: '5%' }}>
              Encuentra las <b>Zonas de recuperación</b> disponibles en tu lugar de trabajo. Completa tu plan de trabajo
              y gana increíbles premios!
            </Typography>
          </Box>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={6}>
              <Button
                onClick={skipIntro}
                size="large"
                sx={{ marginY: '2%', fontWeight: 'bold', width: '100%' }}
                variant="text">
                OMITIR
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={handleNext}
                size="large"
                sx={{ marginY: '2%', fontWeight: 'bold', width: '100%' }}
                variant="text">
                SIGUIENTE
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <img src={onBoarding1} width={'100%'} height={'450px'} />
            <Box
              sx={{
                position: 'absolute',
                bottom: '30px',
                left: '0px',
                marginTop: '10vh',
                width: '100vw',
                maxWidth: '430px',
                maxHeight: '400px',
              }}>
              <OnBoardingPage3 />
            </Box>
          </Box>
          <Box sx={{ paddingX: '5%' }}>
            <Typography variant="h5" sx={{ textAlign: 'center', marginY: '2%', fontWeight: 'bold' }}>
              Asistencia en linea por kinesiólogos
            </Typography>
          </Box>
          <Box sx={{ paddingX: '5%' }}>
            <Typography sx={{ textAlign: 'center', marginY: '5%' }}>
              Queremos que estés bien, por eso contamos con <b>profesionales comprometidos</b> en brindarte atención
              personalizada y soluciones efectivas para mejorar tu calidad de vida.
            </Typography>
          </Box>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={6}>
              <Button
                onClick={skipIntro}
                size="large"
                sx={{ marginY: '2%', fontWeight: 'bold', width: '100%' }}
                variant="text">
                OMITIR
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={handleNext}
                size="large"
                sx={{ marginY: '2%', fontWeight: 'bold', width: '100%' }}
                variant="text">
                SIGUIENTE
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: '100%', paddingX: '5vw' }}>
          <Box
            sx={{ width: '100%', marginTop: '20vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={logo_vrt} height={'100px'} />
          </Box>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box
              sx={{
                marginTop: '10px',
                width: '60vw',
                maxWidth: '430px',
                maxHeight: '400px',
              }}></Box>
          </Box>
          <Box sx={{ width: '100%', marginY: '7vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h5" sx={{ textAlign: 'center', marginY: '2%' }}>
              Registrate para acceder a Makana siempre que lo necesites!
            </Typography>
          </Box>
          <Button
            onClick={handleNext}
            size="large"
            sx={{
              width: '100%',
              marginY: '2%',
              fontWeight: 'bold',
              borderRadius: '50px',
            }}
            variant="contained">
            EMPEZAR MI REGISTRO
          </Button>
        </Box>
        <Box sx={{ paddingX: '5%', paddingY: '5vh' }}>
          <Button onClick={handleBack} size="small" sx={{ marginY: '2%', fontWeight: 'bold' }} variant="text">
            {'← Atras'}
          </Button>
          <FormControl sx={{ width: '100%', marginTop: '10vh' }}>
            <InputLabel id="Bselect-label">Empresa</InputLabel>
            <Select
              value={`${business}`}
              onChange={handleBusiness}
              labelId="Bselect-label"
              id="simple-select"
              label="Empresa"
              variant="outlined"
              sx={{ width: '100%' }}>
              {businesses.map((business) => (
                <MenuItem key={business.id} value={business.id}>
                  {business.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={12}>
            <Button
              disabled={business === null}
              onClick={handleNext}
              size="large"
              sx={{ marginY: '5vh', fontWeight: 'bold', borderRadius: '50px' }}
              variant="contained">
              {'Siguiente →'}
            </Button>
          </Stack>
        </Box>
        <Box sx={{ paddingX: '5%', paddingY: '5vh' }}>
          <Button onClick={handleBack} size="small" sx={{ marginY: '2%', fontWeight: 'bold' }} variant="text">
            {'← Atras'}
          </Button>
          <FormControl sx={{ width: '100%', marginTop: '10vh' }}>
            <h3>Saber tu área de trabajo nos ayuda a recomendarte mejores rutinas ACORDE A TUS NECESIDADES</h3>
            <InputLabel id="Bselect-label">Area</InputLabel>
            {business != null && businesses.filter((b) => b.id === business)[0] !== undefined && (
              <Select
                value={`${activationCode}`}
                onChange={handleDepartment}
                labelId="Bselect-label"
                id="simple-select"
                label="Empresa"
                variant="outlined"
                sx={{ width: '100%' }}>
                {businesses
                  .filter((b) => b.id === business)[0]
                  .departments.map((department) => (
                    <MenuItem key={department.activationCode} value={department.activationCode}>
                      {department.name}
                    </MenuItem>
                  ))}
              </Select>
            )}
          </FormControl>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={12}>
            <Button
              disabled={activationCode === ''}
              onClick={handleNext}
              size="large"
              sx={{ marginY: '5vh', fontWeight: 'bold', borderRadius: '50px' }}
              variant="contained">
              {'Siguiente →'}
            </Button>
          </Stack>
        </Box>
        <Box sx={{ paddingX: '5%' }}>
          <Button onClick={handleBack} size="small" sx={{ marginY: '2%', fontWeight: 'bold' }} variant="text">
            {'← Atras'}
          </Button>
          <TextField
            sx={{ width: '100%', marginY: '2%' }}
            label="RUT"
            variant="outlined"
            value={RUT}
            onChange={changeRUT}
            error={RUTError !== ''}
            helperText={RUTError}
          />
          <TextField
            sx={{ width: '100%', marginY: '2%' }}
            label="Telefono celular"
            variant="outlined"
            value={phone}
            inputProps={{ inputMode: 'numeric' }}
            onChange={changePhone}
            error={phoneError !== ''}
            helperText={phoneError}
          />
          <TextField
            value={name}
            onChange={changeName}
            sx={{ width: '100%', marginY: '2%' }}
            label="Nombre"
            variant="outlined"
          />
          <TextField
            value={lastName}
            onChange={changeLastName}
            sx={{ width: '100%', marginY: '2%' }}
            label="Apellido"
            variant="outlined"
          />
          <TextField
            value={password}
            onChange={changePassword}
            sx={{ width: '100%', marginY: '2%' }}
            label="Contraseña"
            type="password"
          />
          <TextField
            value={confirmation}
            onChange={changeConfirmation}
            sx={{ width: '100%', marginY: '2%' }}
            label="Confirma tu contraseña"
            type="password"
            error={passwordError !== ''}
            helperText={passwordError}
          />
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={12}>
            <Button
              disabled={
                RUT === '' ||
                RUTError !== '' ||
                name === '' ||
                lastName === '' ||
                password === '' ||
                confirmation !== password ||
                passwordError !== '' ||
                phone === '' ||
                phone.length !== 11 ||
                phoneError !== ''
              }
              onClick={handleNext}
              size="large"
              sx={{ marginY: '2%', fontWeight: 'bold', borderRadius: '50px' }}
              variant="contained">
              {'Siguiente →'}
            </Button>
          </Stack>
        </Box>
        <Box sx={{ paddingX: '5%' }}>
          <Button onClick={handleBack} size="small" sx={{ marginY: '2%', fontWeight: 'bold' }} variant="text">
            {'← Atras'}
          </Button>
          <TextField
            value={weight}
            onChange={changeWeight}
            sx={{ width: '100%', marginY: '2%' }}
            label="Peso"
            variant="outlined"
            inputProps={{ inputMode: 'numeric' }}
            InputProps={{
              endAdornment: <InputAdornment position="start">kg</InputAdornment>,
            }}
          />
          <TextField
            value={height}
            onChange={changeHeight}
            sx={{ width: '100%', marginY: '2%' }}
            label="Altura"
            variant="outlined"
            inputProps={{ inputMode: 'numeric' }}
            InputProps={{
              endAdornment: <InputAdornment position="start">cm</InputAdornment>,
            }}
          />
          <p>Fecha de nacimiento</p>
          <DateInput onChange={changeBirthday} />
          <FormControl sx={{ width: '100%', marginY: '2%' }}>
            <InputLabel id="Sselect-label">Sexo</InputLabel>
            <Select
              value={sex}
              onChange={changeSex}
              labelId="Sselect-label"
              id="simple-select"
              label="Sexo"
              variant="outlined"
              sx={{ width: '100%' }}>
              <MenuItem value="M">Hombre</MenuItem>
              <MenuItem value="F">Mujer</MenuItem>
              <MenuItem value="-">Prefiero no decir</MenuItem>
            </Select>
          </FormControl>
          <FormGroup>
            <p>
              Marque la casilla correspondiente si ha sido <b>diagnosticado por un médico</b> con alguna de las
              siguientes enfermedades
            </p>
            <FormControlLabel
              onChange={() => setDiabetes(diabetes === false)}
              control={<Checkbox checked={diabetes} />}
              label="Diabetes"
            />
            <FormControlLabel
              onChange={() => setThrombosis(thrombosis === false)}
              control={<Checkbox checked={thrombosis} />}
              label="Antencedentes de Trombosis"
            />
            <FormControlLabel
              onChange={() => setHeartDisease(heartDisease === false)}
              control={<Checkbox checked={heartDisease} />}
              label="Antencedentes de Enfermedades Cardiovasculares"
            />
          </FormGroup>
          <FormControlLabel control={<Checkbox />} label="Ninguna de las anteriores" />
          <TextField
            value={otherDiseases}
            onChange={changeOtherDiseases}
            sx={{ width: '100%', marginY: '2%' }}
            label="Otras enfermedades o antecedentes"
            variant="outlined"
          />
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={12}>
            <Button
              disabled={birthday === null || sex === '' || height === '' || weight === ''}
              onClick={handleNext}
              size="large"
              sx={{ marginY: '2%', fontWeight: 'bold', borderRadius: '50px' }}
              variant="contained">
              {'Siguiente →'}
            </Button>
          </Stack>
        </Box>
        <Box sx={{ paddingX: '5%' }}>
          <Button onClick={handleBack} size="small" sx={{ marginY: '2%', fontWeight: 'bold' }} variant="text">
            {'← Atras'}
          </Button>
          <Terms content={terms?.content || ''} />
          <Box sx={{ marginX: '5vw' }}>
            <FormControlLabel
              sx={{ marginY: '16px' }}
              onChange={() => setAccept(accept === false)}
              control={<Checkbox checked={accept} />}
              label="He leido y acepto los términos y condiciones de uso"
            />
          </Box>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={12}>
            <Button
              onClick={register}
              size="large"
              sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '50px' }}
              disabled={
                RUT === '' ||
                RUTError !== '' ||
                name === '' ||
                lastName === '' ||
                password === '' ||
                confirmation !== password ||
                birthday === null ||
                passwordError !== '' ||
                sex === '' ||
                height === '' ||
                weight === '' ||
                accept === false
              }
              variant="contained">
              EMPEZAR
            </Button>
          </Stack>
        </Box>
      </SwipeableViews>
    </Box>
  );
};

export default UserRegister;
