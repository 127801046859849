import React, { FC, useEffect } from 'react';
import { Box } from '@mui/material';

const Timer: FC = () => {
  useEffect(() => {
    const isIPhone = /iPhone/i.test(navigator.userAgent);
    const redirectUrl = isIPhone
      ? 'https://apps.apple.com/cl/app/makana/id6654926680' // Reemplaza con el enlace real de tu app en la App Store
      : 'https://play.google.com/store/apps/details?id=cl.makana.app.twa';
    window.location.href = redirectUrl;
  }, []);

  return (
    <Box sx={{ color: '#1b4ed8', textAlign: 'center' }}>
      <h1 className="App-logo-text">
        Espera, estas siendo redireccionado a{' '}
        <a
          href={
            navigator.userAgent.match(/iPhone/i)
              ? 'https://apps.apple.com/cl/app/makana/id6654926680'
              : 'https://play.google.com/store/apps/details?id=cl.makana.app.twa'
          }>
          {navigator.userAgent.match(/iPhone/i) ? 'la App Store' : 'instalar la App'}
        </a>
      </h1>
    </Box>
  );
};

export default Timer;
